import React from "react";

function ParteItem({ item, userData, handleEdit, handleMsgConfirm }) {
  const formatarValor = (valor) => {
    if (valor) {
      const valorFormatado = valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return valorFormatado;
    }
  };
  return (
    <tr key={item._id} className="align-middle">
      <th scope="row" className="text-center">
        {item.type === "juridica" ? (
          <i className="bi bi-building-fill"></i>
        ) : (
          <i className="bi bi-person-fill"></i>
        )}
      </th>
      <td className="text-left capitalize">{item.fullName.toLowerCase()}</td>
      <td>{item.document}</td>
      <td className="no-mobile">{item.contact}</td>
      <td>
        {new Date(item.createdAt.slice(0, -1)).toLocaleDateString("pt-br", {
          day: "numeric",
          month: "numeric",
          year: "numeric"
        })}
      </td>
      <td>
        {item.carteira && <span>R$ {formatarValor(item.carteira)}</span>}
      </td>
      <td className="text-right">
        {userData.admin && (
          <>
            <div
              className="btn btn-warning mx-1"
              onClick={() => {
                handleEdit(item);
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </div>

            <div
              className="btn btn-danger"
              onClick={() => {
                handleMsgConfirm(item);
              }}
            >
              <i className="bi bi-trash3-fill"></i>
            </div>
          </>
        )}
      </td>
    </tr>
  );
}

export default ParteItem;
