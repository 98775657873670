import React /*, { useState } */ from "react";
// import api from "../utils/api.utils";
// import { useNavigate } from "react-router";

export const ViewCaixa = ({
  showModalView,
  imprimirVenda,
  currentCaixa,
  formatarValor,
  cancelView,
  formatarDataEHora,
  handleDevolver
}) => {

  return (
    <div
      className={`border viewVenda p-2 mx-2 rounded w-50 ${
        showModalView ? "d-flex" : "d-none"
      }`}
    >
      <div className="  w-100">
        <div className="">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="modal-title">
              <b>R$ {currentCaixa && formatarValor(currentCaixa.saldoFinal)}</b>
            </h5>

            <div
              className="d-flex align-items-center btn btn-outline-info  close"
              onClick={cancelView}
            >
              <span>&times;</span>
            </div>
          </div>
          <hr />
          <div className="modal-body">
            <div>
              <small>
                <p className="d-flex flex-column">
                  <span>
                    Data:{" "}
                    <b>
                      {currentCaixa &&
                        new Date(currentCaixa.createdAt).toLocaleString(
                          "pt-BR",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit"
                          }
                        )}
                      h
                    </b>
                  </span>

                  <span>
                    Usuário abertura(a):{" "}
                    <b>{currentCaixa && currentCaixa.userAbertura.fullName}</b>
                  </span>
                  <span>
                    Data Fechamento:{" "}
                    <b>
                      {currentCaixa &&
                        new Date(currentCaixa.updatedAt).toLocaleString(
                          "pt-BR",
                          {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit"
                          }
                        )}
                      h
                    </b>
                  </span>
                  <span>
                    Usuário Fechamento(a):{" "}
                    <b>
                      {currentCaixa && currentCaixa.userFechamento.fullName}
                    </b>
                  </span>
                </p>
              </small>
              <hr />

              <ul className="list-produtos">
                {currentCaixa &&
                  currentCaixa.transactions.map((transaction, index) => {
                    return (
                      <li key={index} style={{ fontSize: "0.8rem" }}>
                        <div className="d-flex justify-content-between">
                          <div>
                            <span>
                              {transaction && (
                                <b>
                                  {transaction.type.slice(0, 3)}
                                  {transaction.number}
                                </b>
                              )}
                            </span>{" "}
                            <span>
                              {transaction.parteId && (
                                <>
                                  <i className="bi bi-person-fill"></i>
                                  {transaction.parteId.fullName}
                                </>
                              )}
                            </span>
                          </div>
                          <div>
                            <span>
                              <b>R$ {formatarValor(transaction.valorTotal)}</b>
                            </span>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <hr />
              <small>
                Valor total:{" "}
                <b>
                  R${" "}
                  {currentCaixa && formatarValor(currentCaixa.saldoFinal || 0)}
                </b>
              </small>
              <div className="d-flex justify-content-between mt-3">
                <div
                  className="btn btn-success"
                  onClick={() => {
                    imprimirVenda(currentCaixa, "A4");
                  }}
                  title="Deletar venda"
                >
                  <i className="bi bi-printer"></i> <small>A4</small>
                </div>
                <div
                  className="btn btn-success"
                  onClick={() => {
                    imprimirVenda(currentCaixa, "Cupom");
                  }}
                  title="Deletar venda"
                >
                  <i className="bi bi-printer"></i> <small>Cupom</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
