import React, { useEffect } from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import api from "../utils/api.utils";

import logoIury from "../imgs/iury.png";

const SideBar = ({
  isAdmin,
  setError,
  userData,
  setUserData,
  userId,
  onLogout
}) => {
  const { pathname } = useLocation();
  const newLocation = pathname.split("/");
  const isActive = (path) => newLocation[1] === path;

  useEffect(() => {
    const getUser = async (userId) => {
      try {
        const data = await api.getUserNav(userId);
        setUserData(data);
      } catch (error) {
        setError(error);
      }
    };
    getUser(userId);
  }, []);

  return (
    <>
      <div
        className="px-3 sidebar justify-content-between align-items-center"
        expand="lg"
      >
        <div className="d-flex flex-column">
          <div className="d-flex flex-column align-items-center justify-content-around">
            <img
              style={{ height: "50%" }}
              className="logo"
              src={logoIury}
              alt="Logo Iury"
            />
            <h5>Gyn Distribuidora</h5>
          </div>
          <hr />

          <div id="basic-navbar-nav" className="justify-content-between">
            <div className="mr-auto ">
              <Nav.Link as={Link} to="/meu-caixa/" active={isActive("")}>
                Caixa do dia
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/clientes-fornecedores"
                active={isActive("clientes-fornecedores")}
              >
                Clientes/Fornecedores
              </Nav.Link>

              <Nav.Link as={Link} to="/produtos/" active={isActive("produtos")}>
                Produtos
              </Nav.Link>
              <Nav.Link as={Link} to="/estoque/" active={isActive("estoque")}>
                Dispositivos
              </Nav.Link>

              {isAdmin === true ? (
                <div className="mt-3">
                  <hr />
                  <p className="mt-3">
                    <i className="bi bi-gear-wide-connected"></i> Administração
                  </p>
                  <hr />
                  <Nav.Link
                    as={Link}
                    to="/relatorios/"
                    active={isActive("relatorios")}
                  >
                    História do IMEI
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/compras/transactions"
                    active={isActive("compras")}
                  >
                    Compras
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/vendas/transactions"
                    active={isActive("vendas")}
                  >
                    Vendas
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/devolucao/transactions"
                    active={isActive("devolucao")}
                  >
                    Devoluções
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/todos-caixas"
                    active={isActive("todos-caixas")}
                  >
                    Caixas
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/usuarios"
                    active={isActive("usuarios")}
                  >
                    Usuários
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/auditoria/"
                    active={isActive("auditoria")}
                  >
                    Auditoria
                  </Nav.Link>
                </div>
              ) : null}
              <Nav.Link
                as={Link}
                to=""
                onClick={onLogout}
                active={isActive("/sair")}
              >
                Sair
              </Nav.Link>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <small>Versão 1.13.3</small>
        </div>
      </div>
    </>
  );
};

export default SideBar;
