import React /*, { useState } */ from "react";
// import api from "../utils/api.utils";
// import { useNavigate } from "react-router";

export const ViewImei = ({
  showModalView,
  setShowModalView,
  imprimirVenda,
  currentImei,
  formatarValor,
  formatarDataEHora
}) => {
  const getType = (type) => {
    let newType = type;
    switch (type) {
      case "VENDAS":
        newType = "Venda";
        break;
      case "COMPRAS":
        newType = "Compra";
        break;
      default:
        newType = "Devolução";
        break;
    }
    return newType;
  };

  const transations = [...(currentImei.transactions || [])];
  const listaOrdenada = transations.sort((a, b) => {
    const dataA = a ? a.createdAt : a.createdAt;
    const dataB = b ? b.createdAt : b.createdAt;

    return new Date(dataA) - new Date(dataB);
  });
  return (
    <div
      className={`border viewVenda p-2 mx-2 rounded w-50 ${
        showModalView ? "d-flex" : "d-none"
      }`}
    >
      <div className="  w-100">
        <div className="">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="modal-title">
              IMEI: <b>{currentImei && currentImei.number}</b>
            </h5>

            <div
              className="d-flex align-items-center btn btn-outline-info  close"
              onClick={() => setShowModalView(false)}
            >
              <span>&times;</span>
            </div>
          </div>
          <hr />
          <div className="modal-body">
            <div>
              <small>
                {listaOrdenada.map((item, index) => (
                  <div
                    className={`d-flex flex-column border p-2 ${
                      item.type === "COMPRAS"
                        ? "alert alert-info"
                        : item.type === "VENDAS"
                        ? "alert alert-success"
                        : "alert alert-warning"
                    }`}
                    style={{ borderRadius: "5px" }}
                    key={index}
                  >
                    <h6 className="text-center p-1">
                      {item.type === "COMPRAS" && (
                        <>
                          Entrada{" "}
                          <i className="bi bi-box-arrow-in-down-left"></i>
                        </>
                      )}
                      {item.type === "VENDAS" && (
                        <>
                          Saída <i className="bi bi-box-arrow-up-right"></i>
                        </>
                      )}
                      {item.type === "DEVOLUCAO" && (
                        <>
                          Entrada (devolução){" "}
                          <i className="bi bi-arrow-return-left"></i>
                        </>
                      )}
                    </h6>
                    <span>
                      Data:{" "}
                      <b>{currentImei && formatarDataEHora(item.createdAt)}</b>
                    </span>
                    <span>
                      Número:{" "}
                      <b>
                        {getType(item.type).slice(0, 3).toUpperCase()}
                        {item.number}
                      </b>
                    </span>
                    <span>
                      Valor:{" "}
                      <b>R$ {currentImei && formatarValor(item.valorTotal)}</b>
                    </span>
                    {item.parteId && (
                      <span>
                        {item.type === "COMPRAS" && <>Fornecedor</>}
                        {item.type === "VENDAS" && <>Cliente</>}
                        {item.type === "DEVOLUCAO" && <>Cliente</>}:{" "}
                        <b> {currentImei && item.parteId.fullName}</b>
                      </span>
                    )}
                    {/*
                    <span>
                      Usuário: <b> {currentImei && venda.sell_id.user_sell}</b>
                    </span>{" "}
                    */}
                  </div>
                ))}
              </small>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
