import React, { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import api from "../utils/api.utils";

// import logoIury from "../imgs/iury.png";

const AppNavbar = ({
  isAdmin,
  setError,
  userData,
  setUserData,
  userId,
  onLogout,
}) => {
  const { pathname } = useLocation();
  // const newLocation = pathname.split("/");
  const isActive = (path) => pathname === path;

  useEffect(() => {
    const getUser = async (userId) => {
      try {
        const data = await api.getUserNav(userId);
        setUserData(data);
      } catch (error) {
        setError(error);
      }
    };
    getUser(userId);
  }, []);

  return (
    <>
      <Navbar
        className="px-3 d-flex align-items-center justify-content-between"
        expand="lg"
      >
        <Nav className="mr-auto px-3 nav-atalho">
          <Nav.Link
            as={Link}
            to="/cadastrando/transaction/compras/"
            active={isActive("/cadastrando/transaction/compras/")}
          >
            Cadastrar Compras
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/cadastrando/transaction/vendas/"
            active={isActive("/cadastrando/transaction/vendas/")}
          >
            Cadastrar Vendas
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/cadastrando/transaction/devolucao/"
            active={isActive("/cadastrando/transaction/devolucao/")}
          >
            Devolução
          </Nav.Link>
        </Nav>
        <div
          className="d-flex align-items-center justify-content-end "
          style={{ fontWeight: "bold", marginRight: "10px" }}
        >
          {userData.fullName}
          <i className="bi bi-person-circle mx-1 fs-2"></i>
        </div>
        {/* </Navbar.Collapse> */}
      </Navbar>
    </>
  );
};

export default AppNavbar;
