import React from "react";

export const ModalDeleteParte = ({
  showModalDelete,
  onClose,
  deleteParte,
  currentParte,
}) => {
  return (
    <div
      className={`modal modal-lg ${showModalDelete ? "d-flex" : "d-none"}`}
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="modal-dialog ">
        <div className="modal-content shadow">
          <div className="modal-header">
            <div>
              <h5 className="modal-title">Tem certeza que deseja deletar? </h5>
            </div>
          </div>
          <div className="modal-body">
            <p>
              {currentParte.type === "fisica" ? (
                <>Nome: </>
              ) : (
                <>Razão social: </>
              )}{" "}
              <b>{currentParte.fullName}</b>
            </p>
            <p>
              {currentParte.type === "fisica" ? <>CPF: </> : <>CNPJ: </>}
              {currentParte.document || "vazio"}
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteParte(currentParte._id)}
            >
              Sim
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Não
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
