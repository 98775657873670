// config.js
const config = {
    development: {
      apiBaseUrl: "http://localhost:9000"
    },
    homologation: {
      apiBaseUrl: "http://35.175.178.173:3001"
    },
    production: {
      apiBaseUrl: "https://imeiapi.iuryflores.dev.br/"
    }
  };
  
  export default config;
  