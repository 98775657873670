import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../utils/api.utils";

const RedefinirSenha = ({ setMessage }) => {
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [erro, setErro] = useState("");

  const navigate = useNavigate();

  const { id } = useParams();
  console.log(id);

  const handleSenhaChange = (event) => {
    setSenha(event.target.value);
  };

  const handleConfirmarSenhaChange = (event) => {
    setConfirmarSenha(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (senha !== confirmarSenha) {
      setErro("As senhas não são iguais");
    } else {
      setErro("");
      const newSenha = await api.redefinirSenha(id, { confirmarSenha });
      if (newSenha) {
        setMessage("Senha redefinida com sucesso!");
        navigate("/");
      } else {
        setErro("Não foi possível redefinir senha!");
      }
    }
  };

  return (
    <div className="container">
      <h2 className="mt-5">Redefinir Senha</h2>
      <form onSubmit={handleSubmit} className="mt-3">
        <div className="mb-3">
          <label htmlFor="senha" className="form-label">
            Nova Senha:
          </label>
          <input
            type="password"
            id="senha"
            className="form-control"
            value={senha}
            onChange={handleSenhaChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmarSenha" className="form-label">
            Confirmar Senha:
          </label>
          <input
            type="password"
            id="confirmarSenha"
            className="form-control"
            value={confirmarSenha}
            onChange={handleConfirmarSenhaChange}
            required
          />
        </div>
        {erro && (
          <div className="alert alert-danger" role="alert">
            {erro}
          </div>
        )}
        <button type="submit" className="btn btn-primary">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default RedefinirSenha;
