import React, { useState } from "react";
import { /*useLocation,*/ useNavigate } from "react-router-dom";
import api from "../utils/api.utils";
import ReactDOM from "react-dom";
import PrintTransaction from "../components/PrintTransaction";

export const ViewTransaction = ({
  showModalView,
  currentTransaction,
  formatarValor,
  cancelView,
  formatarDataEHora,
  handleDevolver,
  loading,
  loadingGif,
  setLoading,
  error,
  setError,
  userData
}) => {
  const navigate = useNavigate();

  // const location = useLocation();

  // const { pathname } = location;

  // const entidade = pathname.split("/")[1];

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingProduto, setDeletingProduto] = useState(null);
  const [motivo, setMotivo] = useState("");

  const [editingTransaction, setEditingTransaction] = useState({});
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);

  const [valorCompra, setValorCompra] = useState(0);
  const [valorVenda, setValorVenda] = useState(0);

  // Função para abrir o modal de edição
  const handleEdit = async (compraID) => {
    try {
      // Buscar os detalhes do produto com base no produtoID
      setEditModalOpen(true);
      setLoadingEdit(true);
      const compraData = await api.getCompraByID(compraID);
      setEditingTransaction(compraData);
      if (compraData.produtos.length >= 1) {
        setValorCompra(compraData.produtos[0].valorCompra);
        setValorVenda(compraData.produtos[0].valorVenda);
      }
      setLoadingEdit(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Não foi possível carregar os detalhes da compra!");
    }
  };
  // Função para confirmar a edição
  const confirmEdit = async () => {
    try {
      setLoading(true);
      await api.addPriceTransaction(editingTransaction._id, {
        produtos: editingTransaction.produtos,
        produtoID: editingTransaction.produtos[0].produtoId,
        valorCompra,
        valorVenda
      });
      setLoading(false);
      setEditModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError("Não foi possível editar o produto!");
    }
  };

  // Função para cancelar a edição
  const cancelEdit = () => {
    setEditModalOpen(false);
    setEditingTransaction({});
  };

  // const handleDelete = async (produto) => {
  //   setDeletingProduto(produto);
  //   setDeleteModalOpen(true);
  // };

  const confirmDelete = async () => {
    try {
      setLoading(true);
      const deletedProduto = await api.deleteProdutoDaVenda(
        deletingProduto._id,
        currentTransaction._id
      );
      if (deletedProduto) {
        setLoading(false);
        setDeleteModalOpen(false);
        navigate(0);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setDeletingProduto(null);
  };
  const imprimirVenda = (currentCaixa, folha) => {
    let tamanhoFolha = folha;
    // Abrir nova página com target="_blank"
    const novaPagina = window.open("", "_blank");

    // Adicionar o conteúdo que você deseja imprimir na nova página
    novaPagina.document.write(
      "<html><head><title>Detalhes da Venda</title></head><body>"
    );

    // Adicionar informações específicas
    novaPagina.document.write("<h1>Detalhes da Venda</h1>");
    ReactDOM.render(
      <PrintTransaction
        transaction={currentCaixa}
        formatarDataEHora={formatarDataEHora}
        formatarValor={formatarValor}
        tamanhoFolha={tamanhoFolha}
        userData={userData}
      />,
      novaPagina.document.body
    );

    // Fechar o corpo da página
    novaPagina.document.write("</body></html>");
    novaPagina.document.close();

    // Iniciar a transactions de diálogo de impressão
    novaPagina.print();
  };

  return (
    <div
      className={`border viewVenda p-2 mx-2 rounded w-50 ${
        showModalView ? "d-flex" : "d-none"
      }`}
    >
      <div className="  w-100">
        <div className="">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="modal-title">
              <b>
                R${" "}
                {currentTransaction &&
                  formatarValor(
                    currentTransaction && currentTransaction.valorTotal
                  )}
              </b>
            </h5>
            <div className="d-flex">
              <div
                className="d-flex btn btn-outline-warning mx-2"
                onClick={() => handleEdit(currentTransaction._id)}
              >
                <i className="bi bi-pencil-square"></i>
              </div>
              <div
                className="d-flex align-items-center btn btn-outline-info  close"
                onClick={cancelView}
              >
                <i className="bi bi-x-lg"></i>
              </div>
            </div>
          </div>
          <hr />
          <div className="modal-body">
            <div>
              <small>
                <p className="d-flex flex-column">
                  <span>
                    <big>
                      <b>
                        {currentTransaction &&
                          currentTransaction.type.slice(0, 3)}
                        {(currentTransaction && currentTransaction.number) || 0}
                      </b>
                    </big>
                  </span>
                  <span>
                    Data{" "}
                    <b>
                      {currentTransaction &&
                        formatarDataEHora(
                          (currentTransaction && currentTransaction.date) ||
                            (currentTransaction && currentTransaction.createdAt)
                        )}
                      h
                    </b>
                  </span>
                  {currentTransaction && currentTransaction.parteId && (
                    <span>
                      Cliente:
                      <b>
                        {" "}
                        {(currentTransaction &&
                          currentTransaction.parteId.fullName) ||
                          null}
                      </b>
                    </span>
                  )}
                  <span>
                    {currentTransaction &&
                    currentTransaction.type !== "COMPRAS" ? (
                      <span>Vendedor(a)</span>
                    ) : (
                      <span>Comprador(a)</span>
                    )}
                    :
                    <b>
                      {" "}
                      {currentTransaction &&
                        currentTransaction.transactionUser.fullName}
                    </b>
                  </span>
                </p>
              </small>
              <hr />
              <ul className="list-produtos">
                {currentTransaction &&
                  currentTransaction.produtos.length >= 1 &&
                  currentTransaction.produtos.map((produto, index) => {
                    return (
                      <li
                        key={index}
                        style={{
                          fontSize: "0.8rem",
                          padding: "5px 0",
                          borderBottom: "1px solid lightgray"
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <span style={{ width: "75%" }}>
                            <b>1X </b>-{" "}
                            {produto.produtoId && produto.produtoId.descricao}{" "}
                            {produto.imeiId && (
                              <> - ({produto.imeiId.number})</>
                            )}
                          </span>
                          {currentTransaction.type === "VENDAS" && (
                            <span style={{ width: "25%" }}>
                              <b>R$ {formatarValor(produto.valorVenda)}</b>
                            </span>
                          )}
                          {currentTransaction.type === "COMPRAS" && (
                            <span style={{ width: "25%" }}>
                              <b>R$ {formatarValor(produto.valorCompra)}</b>
                            </span>
                          )}
                          {/* {currentTransaction.type === "VENDAS" && (
                            <div
                              className="btn btn-danger"
                              onClick={() => handleDelete(produto)}
                            >
                              <small>
                                <i className="bi bi-trash-fill"></i>
                              </small>
                            </div>
                          )} */}
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <p>
                <small>
                  Quantidade de itens:{" "}
                  {currentTransaction && currentTransaction.produtos.length}
                </small>
              </p>
              {currentTransaction && currentTransaction.motivo && (
                <p>
                  <b>Motivo: </b>
                  {decodeURIComponent(
                    currentTransaction && currentTransaction.motivo
                  )}
                </p>
              )}
              <small>
                Valor total:{" "}
                <b>
                  R${" "}
                  {currentTransaction &&
                    formatarValor(
                      currentTransaction && currentTransaction.valorTotal
                    )}
                </b>
              </small>
              <div className="d-flex justify-content-between mt-3">
                <>
                  {/* {currentTransaction && currentTransaction && currentTransaction.status === "ATIVA" ? (
                      <div
                        className="btn btn-warning"
                        onClick={() => {
                          handleDevolver(currentTransaction && currentTransaction._id);
                        }}
                        title="Estornar venda"
                      >
                        <i className="bi bi-arrow-return-left "></i>{" "}
                        <small>Estornar venda</small>
                      </div>
                    ) : (
                      <div
                        className="btn btn-secondary disabled"
                        title="Venda devolvida"
                      >
                        <small>DEVOLVIDA</small>
                      </div>
                    )} */}
                  <div
                    className="btn btn-success"
                    onClick={() => {
                      imprimirVenda(currentTransaction, "A4");
                    }}
                    title="Imprimir venda"
                  >
                    <i className="bi bi-printer"></i> <small>A4</small>
                  </div>
                  <div
                    className="btn btn-success"
                    onClick={() => {
                      imprimirVenda(currentTransaction, "Cupom");
                    }}
                    title="Imprimir venda"
                  >
                    <i className="bi bi-printer"></i> <small>Cupom</small>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal ${isDeleteModalOpen ? "d-flex" : "d-none"}`}
        style={{
          justifyContent: "center",
          alignItems: "center"
        }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Tem certeza que deseja excluir este produto?
              </h5>
            </div>
            <div className="modal-body">
              {deletingProduto && (
                <>
                  <p>
                    {deletingProduto.produtoId &&
                      deletingProduto.produtoId.descricao}
                    {deletingProduto.imeiId && (
                      <>
                        {" ("}
                        {deletingProduto.imeiId.number}
                        {")"}
                      </>
                    )}
                    {" | "}
                    <strong>
                      {"R$ "}
                      {currentTransaction.type === "COMPRA"
                        ? formatarValor(deletingProduto.valorCompra)
                        : formatarValor(deletingProduto.valorVenda)}
                    </strong>
                  </p>
                  <div>
                    <label>Motivo da Devolução:</label>
                    <textarea
                      rows={3}
                      className="form-control"
                      value={motivo}
                      onChange={(e) => setMotivo(e.target.value)}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancelDelete}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={confirmDelete}
              >
                Devolver
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal de Edit */}
      <div
        className={`modal ${
          isEditModalOpen ? "d-flex justify-content-end" : "d-none"
        }`}
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "80vw"
        }}
        tabIndex="-1"
        role="dialog"
      >
        <div
          style={{ minWidth: "950px" }}
          className="modal-dialog "
          role="document"
        >
          <div className="modal-content modal-style">
            <div className="modal-header">
              <h5 className="modal-title">
                Editando valores da{" "}
                {editingTransaction.type &&
                  editingTransaction.type.slice(0, -1).toLowerCase()}
              </h5>
            </div>
            <div className="modal-body">
              {!loadingEdit ? (
                <form>
                  <div className="">
                    <strong>Compra Nº {editingTransaction.number || ""}</strong>
                  </div>
                  <div className="">
                    Data da{" "}
                    {editingTransaction.type &&
                      editingTransaction.type.slice(0, -1).toLowerCase()}
                    :{" "}
                    <strong>
                      {formatarDataEHora(editingTransaction.createdAt) || ""}h
                    </strong>
                  </div>
                  <div className="">
                    Fornecedor(a):{" "}
                    <strong>
                      {editingTransaction.parteId &&
                        editingTransaction.parteId.fullName}
                      {" ("}
                      {editingTransaction.parteId &&
                        editingTransaction.parteId.document}
                      {")"}
                    </strong>
                  </div>
                  <div className="">
                    Produto:{" "}
                    <strong>
                      {editingTransaction.produtos &&
                        editingTransaction.produtos.length >= 1 &&
                        editingTransaction.produtos[0].produtoId.descricao}
                    </strong>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="editValorCompra" className="form-label">
                      Valor de Compra:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="editValorCompra"
                      value={valorCompra}
                      onChange={(e) => setValorCompra(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="editValorVenda" className="form-label">
                      Valor de Venda:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="editValorVenda"
                      value={valorVenda}
                      onChange={(e) => setValorVenda(e.target.value)}
                    />
                  </div>
                </form>
              ) : (
                <div className="d-flex justify-content-center">
                  <img
                    style={{ width: "100px" }}
                    src={loadingGif}
                    alt="Loading gif"
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancelEdit}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={confirmEdit}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
