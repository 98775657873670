import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const ViewLancamento = ({
  showModalView,
  imprimirVenda,
  currentLancamento,
  formatarValor,
  cancelView,
  formatarDataEHora,
  formatarData
}) => {
  const { pathname } = useLocation();

  const newPath = pathname.split("/")[1];

  const formaPagamentoMap = {
    pix: "Pix",
    dinheiro: "Dinheiro",
    cartao_debito: "Débito",
    cartao_credito: "Crédito",
    transferencia_bancaria: "Transferência bancária",
    saldo_carteira: "Saldo da Carteira"
  };

  const [lancamento, setLancamento] = useState({
    type: "",
    idLancamento: "",
    formaPagamento: "",
    valorTotal: "",
    dataPagamento: ""
  });

  useEffect(() => {
    if (currentLancamento) {
      setLancamento((prevLancamento) => ({
        ...prevLancamento,
        type: currentLancamento.type.toLowerCase(),
        idLancamento: currentLancamento._id,
        formaPagamento: currentLancamento.formaPagamento,
        valorTotal: currentLancamento.valorTotal,
        dataPagamento: currentLancamento.dataPagamento
      }));
    }
  }, [currentLancamento]);

  return (
    <div
      className={`border viewVenda p-2 mx-2 rounded w-50 ${
        showModalView ? "d-flex" : "d-none"
      }`}
    >
      <div className="w-100">
        <div className="">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="modal-title">
              <b>
                R${" "}
                {currentLancamento && currentLancamento.saldo_carteira ? (
                  <>0,00</>
                ) : (
                  formatarValor(lancamento.valorTotal)
                )}
              </b>
            </h5>
            <div className="d-flex">
              <Link
                to={`/editando/transaction/${lancamento.type}/${lancamento.idLancamento}`}
                className="d-flex btn btn-outline-warning mx-2"
              >
                <i className="bi bi-pencil-square"></i>
              </Link>
              <div
                className="d-flex align-items-center btn btn-outline-info  close"
                onClick={cancelView}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
          <hr />
          <div className="modal-body">
            <div>
              <small>
                <p className="d-flex flex-column">
                  <span>
                    <big>
                      <b>
                        {currentLancamento &&
                          currentLancamento.type.slice(0, 3)}
                        {(currentLancamento && currentLancamento.number) || 0}
                      </b>
                    </big>
                  </span>
                  <span>
                    Data{" "}
                    <b>
                      {currentLancamento &&
                        formatarDataEHora(
                          currentLancamento.date || currentLancamento.createdAt
                        )}
                      h
                    </b>
                  </span>
                  {currentLancamento && currentLancamento.parteId && (
                    <span>
                      Cliente:
                      <b> {currentLancamento.parteId.fullName || null}</b>
                    </span>
                  )}
                  <span>
                    Vendedor(a):
                    <b>
                      {" "}
                      {currentLancamento &&
                        currentLancamento.transactionUser.fullName}
                    </b>
                  </span>
                  {lancamento.formaPagamento && (
                    <span>
                      Forma de pagamento:
                      <b> {formaPagamentoMap[lancamento.formaPagamento]}</b>
                    </span>
                  )}
                  {lancamento.dataPagamento && (
                    <span>
                      Data pagamento:
                      <b> {formatarDataEHora(lancamento.dataPagamento)}h</b>
                    </span>
                  )}
                </p>
                {currentLancamento && currentLancamento.motivo && (
                  <p>
                    <b>Motivo: </b>
                    {decodeURIComponent(currentLancamento.motivo)}
                  </p>
                )}
                <p className="d-flex flex-column">
                  {currentLancamento &&
                  currentLancamento.saldo_carteira === true ? (
                    <span>Usou carteira.</span>
                  ) : (
                    <span>Não usou carteira.</span>
                  )}
                  {currentLancamento &&
                  currentLancamento.destinoProdutos === "Fornecedor" ? (
                    <>
                      <span>Produto retornou para o fornecedor.</span>
                    </>
                  ) : (
                    <span>Produto retornou para o estoque.</span>
                  )}
                </p>
              </small>
              <hr />
              <ul className="list-produtos">
                {currentLancamento &&
                  currentLancamento.produtos.map((produto, index) => {
                    return (
                      <li
                        key={index}
                        style={{
                          fontSize: "0.8rem",
                          padding: "5px 0",
                          borderBottom: "1px solid lightgray"
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <span style={{ width: "75%" }}>
                            <b>1X </b>- {produto.produtoId.descricao}{" "}
                            {produto.imeiId && (
                              <> - ({produto.imeiId.number})</>
                            )}
                          </span>
                          <span style={{ width: "25%" }}>
                            <b>R$ {formatarValor(produto.valorVenda)}</b>
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <p>
                <small>
                  Quantidade de itens:{" "}
                  {currentLancamento && currentLancamento.produtos.length}
                </small>
              </p>
              <small>
                Valor total:{" "}
                <b>
                  R${" "}
                  {currentLancamento.saldo_carteira ? (
                    <>0,00</>
                  ) : (
                    formatarValor(currentLancamento.valorTotal)
                  )}
                </b>
              </small>
              <div className="d-flex justify-content-between mt-3">
                {newPath !== "caixa" && (
                  <>
                    {/* {currentLancamento && currentLancamento.status === "ATIVA" ? (
                      <div
                        className="btn btn-warning"
                        onClick={() => {
                          handleDevolver(currentLancamento._id);
                        }}
                        title="Estornar venda"
                      >
                        <i className="bi bi-arrow-return-left "></i>{" "}
                        <small>Estornar venda</small>
                      </div>
                    ) : (
                      <div
                        className="btn btn-secondary disabled"
                        title="Venda devolvida"
                      >
                        <small>DEVOLVIDA</small>
                      </div>
                    )} */}
                    <div
                      className="btn btn-success"
                      onClick={() => {
                        imprimirVenda(currentLancamento, "A4");
                      }}
                      title="Deletar venda"
                    >
                      <i className="bi bi-printer"></i> <small>A4</small>
                    </div>
                    <div
                      className="btn btn-success"
                      onClick={() => {
                        imprimirVenda(currentLancamento, "Cupom");
                      }}
                      title="Deletar venda"
                    >
                      <i className="bi bi-printer"></i> <small>Cupom</small>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
