import React, { useState, useEffect, useRef } from "react";

const ImeiReaderVenda = ({ onImeiAdd }) => {
  const [imei, setImei] = useState("");

  const imeiInputRef = useRef(null);

  useEffect(() => {
    if (!imei) {
      imeiInputRef.current.focus();
    }
  }, [imei]);

  const handleImeiChange = (event) => {
    setImei(event.target.value);
  };

  const handleImeiKeyPress = (event) => {
    if (event.key === "Enter") {
      onImeiAdd(imei);
      setImei("");
    }
  };

  return (
    <div className="d-flex justify-content-between flex-wrap">
      <div className="form-group col-12 col-md-7">
        <label>Leitura de IMEI/Serial:</label>
        <input
          className="form-control"
          type="text"
          value={imei}
          onChange={handleImeiChange}
          // onBlur={() => addWithNoTimer(imei, serial)}
          placeholder="Insira o IMEI aqui!"
          ref={imeiInputRef}
          onKeyPress={handleImeiKeyPress}
        />
      </div>
    </div>
  );
};

export default ImeiReaderVenda;
