import React, { useState, useEffect, useRef } from "react";

const ImeiReader = ({ onImeiAdd }) => {
  const [imei, setImei] = useState("");
  const [serial, setSerial] = useState("");

  const imeiInputRef = useRef(null);
  const serialInputRef = useRef(null);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (imei) {
  //       onImeiAdd(imei, serial);
  //       setImei("");
  //       setSerial("");

  //       serialInputRef.current.focus();
  //     }
  //     if (serial) {
  //       setImei("");
  //       imeiInputRef.current.focus();
  //     }
  //   }, 60000); // Aguarde segundos após a última alteração

  //   return () => clearTimeout(timer);
  // }, [imei, serial, onImeiAdd]);

  // useEffect(() => {
  //   if (!imei && !serial) {
  //     serialInputRef.current.focus();
  //   }
  // }, [imei, serial]);

  // const handleImeiChange = (event) => {
  //   setImei(event.target.value);

  //   // setSerial(serial);
  //   // inputRef.current.focus();
  // };
  // const handleSerialChange = (event) => {
  //   setSerial(event.target.value);
  // };

  // const addWithNoTimer = (imei, serial) => {
  //   onImeiAdd(imei, serial);
  //   setImei("");
  //   setSerial("");
  // };

  useEffect(() => {
    if (!imei && !serial) {
      if (isToggled) {
        serialInputRef.current.focus();
      }
    }
  }, [imei, serial]);

  const handleImeiChange = (event) => {
    setImei(event.target.value);
  };

  const handleSerialChange = (event) => {
    setSerial(event.target.value);
  };

  const handleSerialKeyPress = (event) => {
    if (event.key === "Enter") {
      imeiInputRef.current.focus();
    }
  };

  const handleImeiKeyPress = (event) => {
    if (event.key === "Enter") {
      onImeiAdd(imei, serial);
      setImei("");
      setSerial("");
    }
  };

  const [isToggled, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!isToggled);
  };
  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div
          className={`toggle-container ${isToggled ? "toggled" : ""} mx-1`}
          onClick={handleToggle}
        >
          <div className="toggle-circle"></div>
        </div>
        <b>Utilizar Serial?</b>
      </div>
      <div className="d-flex justify-content-between flex-wrap">
        {isToggled && (
          <div className="form-group col-12 col-md-4">
            <label>Outro Serial:</label>
            <input
              className="form-control"
              type="text"
              value={serial}
              onChange={handleSerialChange}
              placeholder=""
              ref={serialInputRef}
              onKeyPress={handleSerialKeyPress}
            />
          </div>
        )}
        <div
          className={`form-group col-12 ${
            isToggled ? "col-md-7" : "col-md-12"
          }`}
        >
          <label>Leitura de IMEI/Serial:</label>
          <input
            className="form-control"
            type="text"
            value={imei}
            onChange={handleImeiChange}
            // onBlur={() => addWithNoTimer(imei, serial)}
            placeholder="Insira o IMEI aqui!"
            ref={imeiInputRef}
            onKeyPress={handleImeiKeyPress}
          />
        </div>
      </div>
    </div>
  );
};

export default ImeiReader;
