import React from "react";

const ModalDeleteImei = ({
  isDeleteModalOpen,
  cancelDelete,
  confirmDelete,
  lastTransaction,
  currentIMEI
}) => {
  return (
    <div
      className={`modal ${isDeleteModalOpen ? "d-flex" : "d-none"}`}
      style={{
        justifyContent: "center",
        alignItems: "center"
      }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" style={{ minWidth: "60%" }} role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirmar Exclusão</h5>
          </div>
          <div className="modal-body d-flex flex-column">
            <span>Tem certeza que deseja excluir o item abaixo?</span>
            <p className="d-flex flex-column mt-3">
              <span>
                <strong>IMEI: </strong>
                {currentIMEI.number}
              </span>

              <span>
                <strong> {lastTransaction && lastTransaction.type}: </strong>{" "}
                {lastTransaction && lastTransaction.number}{" "}
              </span>

              <span>
                <strong>Produto: </strong>
                {currentIMEI.produtoId && currentIMEI.produtoId.descricao}
              </span>
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={cancelDelete}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={confirmDelete}
            >
              Excluir
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDeleteImei;
