import React, { useState, useEffect } from "react";
import api from "../utils/api.utils";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { ViewCaixa } from "../components/ViewCaixa";
import PrintCaixa from "../components/PrintCaixa";

const TodosCaixas = ({
  message,
  setMessage,
  loading,
  setLoading,
  loadingGif,
  formatarData,
  formatarDataEHora,
  showModal,
  setShowModal,
  closeModal,
  userId,
  userData
}) => {
  const [caixas, setCaixas] = useState("");
  const [transactions, setTransactions] = useState([]);
  // const [currentTransaction, setCurrentTransaction] = useState("");
  const [currentCaixa, setCurrentCaixa] = useState("");
  const [showModalView, setShowModalView] = useState(false);

  useEffect(() => {
    const getCaixas = async () => {
      try {
        setLoading(true);
        const data = await api.getTodosCaixas();
        setCaixas(data);
        setTransactions(data[0].transactions);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getCaixas();
  }, []);

  const formatarValor = (valor) => {
    if (valor) {
      const valorFormatado = valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return valorFormatado;
    } else {
      return 0.0;
    }
  };

  const viewCaixa = (caixa) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setCurrentCaixa(caixa);
    setShowModalView(true);
  };

  const cancelView = () => {
    setShowModalView(false);
    setCurrentCaixa(null);
  };

  const imprimirVenda = (currentCaixa, folha) => {
    let tamanhoFolha = folha;
    // Abrir nova página com target="_blank"
    const novaPagina = window.open("", "_blank");

    // Adicionar o conteúdo que você deseja imprimir na nova página
    novaPagina.document.write(
      "<html><head><title>Detalhes da Venda</title></head><body>"
    );

    // Adicionar informações específicas
    novaPagina.document.write("<h1>Detalhes da Venda</h1>");
    ReactDOM.render(
      <PrintCaixa
        caixa={currentCaixa}
        formatarDataEHora={formatarDataEHora}
        formatarValor={formatarValor}
        tamanhoFolha={tamanhoFolha}
        userData={userData}
      />,
      novaPagina.document.body
    );

    // Fechar o corpo da página
    novaPagina.document.write("</body></html>");
    novaPagina.document.close();

    // Iniciar a transactions de diálogo de impressão
    novaPagina.print();
  };
  const renderTableAbertos = () => {
    if (caixas.length > 0 && caixas.some((caixa) => caixa.status === true)) {
      return (
        <table className="table mb-0 table-striped table-hover">
          <thead>
            <tr>
              <th className="text-center">Status</th>
              <th>Data do caixa</th>
              <th>Valor total</th>
              <th>Última venda</th>
              <th>Usuário da abertura</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {caixas.map((caixa, index) => {
              if (caixa.status === true) {
                var dataObj = new Date(caixa.dataAbertura);
                var dia = dataObj.getUTCDate();
                var mes = dataObj.getUTCMonth() + 1;
                var ano = dataObj.getUTCFullYear();

                var dataFormatada = dia + "/" + mes + "/" + ano;

                let valorTotalVendas = 0;
                for (
                  let index = 0;
                  index < caixa.transactions.length;
                  index++
                ) {
                  valorTotalVendas += caixa.transactions[index].valorTotal;
                }
                return (
                  <tr key={index}>
                    <td className="text-center">
                      <div
                        className={
                          caixa.status === true
                            ? "btn btn-info"
                            : "btn btn-dark"
                        }
                      >
                        {caixa.status === true ? "Aberto" : "Fechado"}
                      </div>
                    </td>
                    <td>{dataFormatada}</td>
                    <td>R$ {formatarValor(valorTotalVendas)}</td>
                    <td>{formatarDataEHora(caixa.updatedAt)}h</td>
                    <td>{caixa.userAbertura.fullName}</td>
                    <td className="text-center" style={{ width: "5%" }}>
                      {caixa.status === true ? (
                        <Link
                          to={`/caixa/fechando/${caixa._id}`}
                          className="btn btn-success"
                        >
                          Fechar
                        </Link>
                      ) : null}
                    </td>
                  </tr>
                );
              } else {
                return null;
              }
            })}
          </tbody>
        </table>
      );
    } else {
      return (
        <div className="text-center alert alert-info">Nenhum caixa aberto!</div>
      );
    }
  };

  const renderTableFechados = () => {
    if (caixas.length > 0 && caixas.some((caixa) => caixa.status === false)) {
      return (
        <table className="table mb-0 table-striped table-hover">
          <thead>
            <tr>
              <th className="text-center">Status</th>
              <th>Data</th>
              <th>Valor total</th>
              <th>Valor do fechamento</th>
              <th>Usuário que fechou</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {caixas.map((caixa, index) => {
              if (caixa.status === false) {
                var dataObj = new Date(caixa.createdAt);
                var dia = dataObj.getUTCDate();
                var mes = dataObj.getUTCMonth() + 1;
                var ano = dataObj.getUTCFullYear();

                var dataFormatada = dia + "/" + mes + "/" + ano;

                let valorTotalVendas = 0;
                for (
                  let index = 0;
                  index < caixa.transactions.length;
                  index++
                ) {
                  valorTotalVendas += caixa.transactions[index].valorTotal;
                }
                return (
                  <tr key={index}>
                    <td className="text-center">
                      <div
                        className={
                          transactions.status === true
                            ? "btn btn-info"
                            : "btn btn-dark"
                        }
                      >
                        {transactions.status === true ? "Aberto" : "Fechado"}
                      </div>
                    </td>
                    <td>{dataFormatada}</td>
                    <td>R$ {formatarValor(valorTotalVendas)}</td>
                    <td>R$ {formatarValor(caixa.saldoFinal)}</td>
                    <td>{caixa.userFechamento.fullName}</td>
                    <td style={{ width: "5%" }}>
                      <div
                        className="btn btn-outline-info "
                        onClick={() => {
                          viewCaixa(caixa);
                        }}
                        title="Visualizar venda"
                      >
                        <i className="bi bi-eye-fill "></i>
                      </div>
                    </td>
                  </tr>
                );
              } else {
                return null;
              }
            })}
          </tbody>
        </table>
      );
    } else {
      return (
        <div className="text-center alert alert-info">
          Nenhum caixa foi fechado!
        </div>
      );
    }
  };

  return (
    <div
      className="p-3 d-flex flex-column"
      style={{ marginTop: "70px", width: "79vw", marginBottom: "100px" }}
    >
      <div className="d-flex align-items-baseline justify-content-between">
        <h3>
          <i className="bi bi-cash-coin"></i> Caixas
        </h3>
      </div>
      <hr />
      {message ? <div className="alert alert-success">{message}</div> : null}
      {!loading ? (
        <div className="d-flex align-items-start">
          <div className="border p-2  rounded w-100 d-flex flex-column">
            <div className="border p-2  shadow rounded w-100">
              {renderTableAbertos()}
            </div>
            <hr />
            <div className="border p-2 mt-3 shadow rounded w-100">
              {renderTableFechados()}
            </div>
          </div>
          <ViewCaixa
            setShowModalView={setShowModalView}
            showModalView={showModalView}
            currentCaixa={currentCaixa}
            formatarValor={formatarValor}
            formatarDataEHora={formatarDataEHora}
            cancelView={cancelView}
            imprimirVenda={imprimirVenda}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <img style={{ width: "100px" }} src={loadingGif} alt="Loading gif" />
        </div>
      )}
    </div>
  );
};

export default TodosCaixas;
