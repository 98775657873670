import React, { useEffect, useState } from "react";
import api from "../utils/api.utils";

export const Auditoria = ({ loading, setLoading, loadingGif, userId }) => {
  const [auditorias, setAuditorias] = useState([]);

  useEffect(() => {
    const getAuditoria = async () => {
      try {
        const data = await api.getAuditorias();
        setAuditorias(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getAuditoria();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderTableRows = () => {
    auditorias.sort((a, b) => b.number - a.number);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const current = auditorias.slice(indexOfFirstItem, indexOfLastItem);

    return current.map((auditoria, index) => {
      let className = "";

      switch (auditoria.operacao) {
        case "DELETE":
          className = "danger";
          break;
        case "CADASTRO":
          className = "info";
          break;
        case "LOGIN":
          className = "success";
          break;
        case "EDICAO":
          className = "warning";
          break;
        default:
          className = "";
      }

      //formatar data e hora
      const formatarDataEHora = (dataParaFormatar) => {
        return new Date(dataParaFormatar).toLocaleDateString("pt-br", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZone: "America/Sao_Paulo",
          hour12: false
        });
      };

      const dataAud = formatarDataEHora(auditoria.createdAt);

      return (
        <tr key={index} className={`table-${className}`}>
          <td className="text-center">{dataAud}</td>
          <td>{auditoria.operacao}</td>
          <td>{auditoria.entidade}</td>
          <td>{auditoria.descricao}</td>
          <td>{auditoria.userId && auditoria.userId.fullName}</td>
        </tr>
      );
    });
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(auditorias.length / itemsPerPage);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <nav className="d-flex justify-content-center">
        <ul className="pagination flex-wrap">
          {pageNumbers.map((number) => (
            <li key={number} className="page-item">
              <button onClick={() => paginate(number)} className="page-link">
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };
  return (
    <div
      className="p-3 d-flex flex-column align-items-center"
      style={{ marginTop: "70px", width: "79vw", marginBottom: "150px" }}
    >
      <h1>Ações do sistema</h1>
      {!loading ? (
        <div className="border p-2 shadow rounded w-100">
          <table className="table mb-0 table-striped table-hover">
            <thead>
              <tr>
                <th className="text-center">Data</th>
                <th>Operação</th>
                <th>Entidade</th>
                <th>Descrição</th>
                <th>Usuário</th>
              </tr>
            </thead>
            <tbody>
              {auditorias.length > 0 ? (
                renderTableRows()
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    Nenhuma ação feita no sistema!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <img style={{ width: "100px" }} src={loadingGif} alt="Loading gif" />
        </div>
      )}
      {renderPagination()}
    </div>
  );
};
