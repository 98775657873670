import React from "react";

function FilterAlphabet({
  alphabetList,
  selectedAlphabet,
  onAlphabetChange,
  handleClickHash
}) {
  const handleAlphabetClick = (alphabet) => {
    onAlphabetChange(alphabet);
  };

  return (
    <div className="d-flex justify-content-around flex-wrap">
      <button
        className="button-filter btn"
        onClick={handleClickHash}
        // style={{ backgroundColor: "lightgrey" }}

        style={{
          fontWeight: selectedAlphabet === "" ? "bold" : "normal",
          backgroundColor: selectedAlphabet === "" ? "black" : "lightgrey",
          color: selectedAlphabet === "" ? "white" : "black"
        }}
      >
        <small>#</small>
      </button>
      {alphabetList.map((alphabet) => (
        <button
          className="button-filter btn"
          key={alphabet}
          onClick={() => handleAlphabetClick(alphabet)}
          style={{
            fontWeight: alphabet === selectedAlphabet ? "bold" : "normal",
            backgroundColor:
              alphabet === selectedAlphabet ? "black" : "lightgrey",
            color: alphabet === selectedAlphabet ? "white" : "black"
          }}
        >
          <small>{alphabet}</small>
        </button>
      ))}
    </div>
  );
}

export default FilterAlphabet;
