import React from "react";
// import ReactDOM from "react-dom";

const PrintCaixa = ({
  caixa,
  formatarDataEHora,
  formatarValor,
  tamanhoFolha,
  userData
}) => {

  const dataAtual = new Date();
  const dataFormatada = `${dataAtual.toLocaleDateString(
    "pt-BR"
  )} ${dataAtual.toLocaleTimeString("pt-BR")}`;

  const styles = {
    container: {
      fontFamily:
        tamanhoFolha === "Cupom" ? "Arial, sans-serif" : "Arial, sans-serif",
      padding: tamanhoFolha === "Cupom" ? "5px" : "20px",
      width: tamanhoFolha === "Cupom" ? "80mm" : "29,7cm",
      minHeight: "95vh", // Garante que o container ocupe toda a altura da viewport
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column"
    },
    cupom: {
      fontSize: tamanhoFolha === "Cupom" ? "0.4rem" : "2rem"
    },
    header: {
      marginBottom: "20px"
    },
    distributor: {
      fontSize: tamanhoFolha === "Cupom" ? "0.6rem" : "1.2rem",
      fontWeight: "bold"
    },
    title: {
      textAlign: "center",
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.5rem",
      marginBottom: "10px"
    },
    hr: {
      border: "1px solid #ccc"
    },
    content: {
      flex: 1 // Faz com que o conteúdo ocupe todo o espaço disponível
    },
    infoContainer: {
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.2rem",

      marginBottom: "20px"
    },
    valuesContainer: {
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.2rem",

      marginTop: "20px"
    },
    subtitle: {
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.2rem",
      marginBottom: "10px"
    },
    table: {
      width: tamanhoFolha === "Cupom" ? "75mm" : "100%",
      margin: "0",
      borderCollapse: "collapse",
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.2rem"
    },

    footer: {
      marginTop: "auto", // Empurra o rodapé para a parte inferior
      paddingTop: "10px",
      textAlign: "center"
    },
    systemName: {
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.2rem",
      fontWeight: "bold"
    },
    printDate: {
      fontSize: tamanhoFolha === "Cupom" ? "0.7rem" : "0.8rem"
    },
    evenRow: {
      backgroundColor: "#f2f2f2",
      borderBottom: "1px solid lightgray"
    },
    oddRow: {
      backgroundColor: "#ffffff",
      borderBottom: "1px solid lightgray"
    },
    descriptionColumn: {
      padding: "10px",
      textAlign: "left"
    },
    valueColumn: {
      padding: "10px",
      textAlign: "right"
    },
    quantityColumn: {
      padding: "10px",
      textAlign: "center"
    },
    totalRow: {
      backgroundColor: "#ccc"
    },
    totalLabelColumn: {
      padding: "10px",
      textAlign: "right",
      fontWeight: "bold"
    },
    totalValueColumn: {
      padding: "10px",
      textAlign: "right",
      fontWeight: "bold"
    },
    assinatura: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  };
  const containerStyle = {
    ...styles.container,
    width: tamanhoFolha === "A4" ? "290mm" : "80mm"
  };
  return (
    <div style={containerStyle}>
      <div style={styles.header}>
        <p style={styles.distributor}>Gyn Distribuidora</p>
        <h1 style={styles.title}>Detalhes do Caixa</h1>
        <hr style={styles.hr} />
      </div>

      <div style={styles.content}>
        <div style={styles.infoContainer}>
          <div className="d-flex flex-column">
            <p>
              Data Abertura:{" "}
              <b>
                {caixa &&
                  new Date(caixa.createdAt).toLocaleString("pt-BR", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                  })}
                h
              </b>
            </p>

            <p>
              Usuário abertura(a): <b>{caixa && caixa.userAbertura.fullName}</b>
            </p>
            <p>
              Data Fechamento:{" "}
              <b>
                {caixa &&
                  new Date(caixa.updatedAt).toLocaleString("pt-BR", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                  })}
                h
              </b>
            </p>
            <p>
              Usuário Fechamento(a):{" "}
              <b>{caixa && caixa.userFechamento.fullName}</b>
            </p>
          </div>
        </div>

        <div style={styles.valuesContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Transação</th>
                <th style={{ textAlign: "left" }}>Cliente</th>
                <th style={{ textAlign: "right" }}>Valor</th>
              </tr>
            </thead>
            <tbody>
              {caixa.transactions.map((item, index) => (
                <tr
                  key={index}
                  style={index % 2 === 0 ? styles.evenRow : styles.oddRow}
                >
                  <td style={styles.descriptionColumn}>
                    {item && (
                      <b>
                        {item.type.slice(0, 3)}
                        {item.number}
                      </b>
                    )}
                  </td>
                  <td>
                    <i className="bi bi-person-fill"></i>
                    {item.parteId && item.parteId.fullName}
                  </td>
                  <td style={styles.valueColumn}>
                    R$ {formatarValor(item.valorTotal)}
                  </td>
                </tr>
              ))}
              {/* Linha do total */}
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr style={styles.totalRow}>
                <td colSpan="2" style={styles.totalLabelColumn}>
                  Total:
                </td>
                <td style={styles.totalValueColumn}>
                  R$ {formatarValor(caixa.saldoFinal)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div style={styles.footer}>
        <big>
          <div style={styles.assinatura}>
            <hr
              style={{
                border: "1px solid black",
                maxWidth: "300px",
                width: "100%"
              }}
            />
            <span>{userData.fullName.toUpperCase()}</span>
          </div>
        </big>
        <br />
        <br />
        <br />
        <br />
        <hr />
        <p style={styles.systemName}>ImeiGyn - Sistema de Gestão de Vendas</p>
        <p style={styles.printDate}>Data da Impressão: {dataFormatada}</p>
      </div>
    </div>
  );
};

export default PrintCaixa;
