import React from "react";

const PrintTransaction = ({
  transaction,
  formatarDataEHora,
  formatarValor,
  tamanhoFolha,
}) => {
  const dataAtual = new Date();
  const dataFormatada = `${dataAtual.toLocaleDateString(
    "pt-BR"
  )} ${dataAtual.toLocaleTimeString("pt-BR")}`;

  const styles = {
    container: {
      fontFamily:
        tamanhoFolha === "Cupom" ? "Arial, sans-serif" : "Arial, sans-serif",
      padding: tamanhoFolha === "Cupom" ? "5px" : "20px",
      width: "100%",
      minHeight: "95vh", // Garante que o container ocupe toda a altura da viewport
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
    },
    cupom: {
      fontSize: tamanhoFolha === "Cupom" ? "0.4rem" : "2rem",
    },
    header: {
      marginBottom: "20px",
    },
    distributor: {
      fontSize: tamanhoFolha === "Cupom" ? "0.6rem" : "1.2rem",
      fontWeight: "bold",
    },
    title: {
      textAlign: "center",
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.5rem",
      marginBottom: "10px",
    },
    hr: {
      border: "1px solid #ccc",
    },
    content: {
      flex: 1, // Faz com que o conteúdo ocupe todo o espaço disponível
    },
    infoContainer: {
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.2rem",

      marginBottom: "20px",
    },
    valuesContainer: {
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.2rem",

      marginTop: "20px",
    },
    subtitle: {
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.2rem",
      marginBottom: "10px",
    },
    table: {
      width: tamanhoFolha === "Cupom" ? "75mm" : "100%",
      margin: "0",
      borderCollapse: "collapse",
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.2rem",
    },

    footer: {
      marginTop: "auto", // Empurra o rodapé para a parte inferior
      borderTop: "1px solid #ccc",
      paddingTop: "10px",
      textAlign: "center",
    },
    systemName: {
      fontSize: tamanhoFolha === "Cupom" ? "0.8rem" : "1.2rem",
      fontWeight: "bold",
    },
    printDate: {
      fontSize: tamanhoFolha === "Cupom" ? "0.7rem" : "0.8rem",
    },
    evenRow: {
      backgroundColor: "#f2f2f2",
    },
    oddRow: {
      backgroundColor: "#ffffff",
    },
    descriptionColumn: {
      padding: "10px",
      textAlign: "left",
    },
    valueColumn: {
      padding: "10px",
      textAlign: "right",
    },
    quantityColumn: {
      padding: "10px",
      textAlign: "center",
    },
    totalRow: {
      backgroundColor: "#ccc",
    },
    totalLabelColumn: {
      padding: "10px",
      textAlign: "right",
      fontWeight: "bold",
    },
    totalValueColumn: {
      padding: "10px",
      textAlign: "right",
      fontWeight: "bold",
    },
  };
  const containerStyle = {
    ...styles.container,
    width: tamanhoFolha === "A4" ? "210mm" : "80mm",
  };

  const getType = (type) => {
    let newType = type;
    switch (type) {
      case "VENDAS":
        newType = "Venda";
        break;
      case "COMPRAS":
        newType = "Compra";
        break;
      default:
        newType = "Devolução";
        break;
    }
    return newType;
  };

  return (
    <div style={containerStyle}>
      <div style={styles.header}>
        <p style={styles.distributor}>Gyn Distribuidora</p>
        <h1 style={styles.title}>Detalhes da {getType(transaction.type)}</h1>
        <hr style={styles.hr} />
      </div>

      <div style={styles.content}>
        <div style={styles.infoContainer}>
          <p>
            <strong>Número da {getType(transaction.type)}:</strong>{" "}
            {transaction.type.slice(0, 3)}
            {transaction.number}
            <br />
            <strong>Data da {getType(transaction.type)}:</strong>{" "}
            {formatarDataEHora(transaction.createdAt)}
            h
            <br />
            <strong>Vendedor(a):</strong> {transaction.transactionUser.fullName}
            <br />
            {transaction.parteId && (
              <>
                <strong>Cliente:</strong> {transaction.parteId.fullName}
                <br />
              </>
            )}
            {transaction.motivo && (
              <>
                <strong>Motivo da devolução:</strong> {transaction.motivo}
                <br />
              </>
            )}
          </p>
        </div>

        <div style={styles.valuesContainer}>
          {/* <h2 style={styles.subtitle}>Lista de itens</h2> */}
          <table style={styles.table}>
            <thead>
              <tr>
                <th></th>
                <th>Descrição</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {transaction.produtos.map((item, index) => (
                <tr
                  key={index}
                  style={index % 2 === 0 ? styles.evenRow : styles.oddRow}
                >
                  <td>1 x </td>
                  <td style={styles.descriptionColumn}>
                    {item.produtoId.descricao}{" "}
                    {item.produtoId.hasImei && <>- ({item.imeiId.number})</>}
                  </td>
                  <td style={styles.valueColumn}>
                    R$ {formatarValor(item.valorVenda)}
                  </td>
                </tr>
              ))}
              {/* Linha do total */}
              <tr style={styles.totalRow}>
                <td colSpan="2" style={styles.totalLabelColumn}>
                  Total:
                </td>
                <td style={styles.totalValueColumn}>
                  R$ {formatarValor(transaction.valorTotal)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div style={styles.footer}>
        <p style={styles.systemName}>ImeiGyn - Sistema de Gestão de Vendas</p>
        <p style={styles.printDate}>Data da Impressão: {dataFormatada}</p>
      </div>
    </div>
  );
};

export default PrintTransaction;
