import React, { useState, useEffect } from "react";
import api from "../utils/api.utils";
import { FecharCaixa } from "../components/FecharCaixa";
import { Link, useParams } from "react-router-dom";
import { ViewLancamento } from "../components/ViewLancamento.js";

const FechandoCaixa = ({
  message,
  setMessage,
  loading,
  setLoading,
  loadingGif,
  formatarData,
  formatarDataEHora,
  showModal,
  setShowModal,
  closeModal,
  userId
}) => {
  const [caixa, setCaixa] = useState([]);
  const [transactions, setTransactions] = useState("");

  const [error, setError] = useState(null);

  const { caixa_id } = useParams();
  useEffect(() => {
    const getVendas = async () => {
      try {
        setLoading(true);
        const data = await api.getVendasByCaixaId(caixa_id);
        setCaixa(data);
        setTransactions(data.transactions);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getVendas();
  }, []);

  const formatarValor = (valor) => {
    if (valor) {
      const valorFormatado = valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return valorFormatado;
    }
  };

  let valorTotalVendas = 0;
  for (let index = 0; index < transactions.length; index++) {
    valorTotalVendas += transactions[index].valorTotal;
  }

  // const formatarDataSemHora = (dataParaFormatar) => {
  //   let dataObj = new Date(dataParaFormatar);
  //   let dia = dataObj.getUTCDate();
  //   let mes = dataObj.getUTCMonth() + 1;
  //   let ano = dataObj.getUTCFullYear();
  //   let dataFormatada = dia + "/" + mes + "/" + ano;
  //   return dataFormatada;
  // };

  const [showCaixaModal, setShowCaixaModal] = useState(false);

  const [showVendaModal, setShowVendaModal] = useState(null);

  const [currentVenda, setCurrentVenda] = useState("");

  const fecharCaixa = (caixaId) => {
    setShowCaixaModal(true);
  };

  const closeFecharCaixaModal = () => {
    setShowCaixaModal(false);
  };
  const showVenda = (venda) => {
    setCurrentVenda(venda);
    setShowVendaModal(true);
  };
  const cancelView = () => {
    setShowVendaModal(false);
    setCurrentVenda(null);
  };

  const renderTable = () => {
    if (caixa && transactions.length > 0) {
      return (
        <table className="table mb-0 table-striped table-hover">
          <thead>
            <tr>
              <th>Data</th>
              <th>Descrição</th>
              <th>Valor</th>
              <th>Vendedor</th>
              <th className="text-center" style={{ width: "5%" }}>
                Visualizar
              </th>
            </tr>
          </thead>
          <tbody>
            {caixa.transactions.map((transaction, index) => {
              return (
                <tr key={index}>
                  <td>{formatarData(transaction.createdAt)}</td>
                  <td>
                    {caixa && (
                      <b>
                        {transaction.type.slice(0, 3)}
                        {transaction.number}
                      </b>
                    )}
                  </td>
                  <td>R$ {formatarValor(transaction.valorTotal)}</td>
                  <td>{transaction.transactionUser.fullName}</td>
                  <td className="text-center">
                    <div
                      className="btn btn-outline-info "
                      onClick={() => showVenda(transaction)}
                    >
                      <i className="bi bi-eye-fill clickable"></i>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return (
        <div className="text-center text-dark">
          Nenhum lançamento cadastrado!
        </div>
      );
    }
  };

  return (
    <div
      className="p-3 d-flex flex-column"
      style={{ marginTop: "70px", width: "79vw", marginBottom: "100px" }}
    >
      <div className="d-flex align-items-baseline justify-content-between">
        <h3>
          <i className="bi bi-coin"></i> Caixa do dia{" "}
          {formatarData(caixa.dataAbertura)}
        </h3>
        <div className="">
          <Link className="btn btn-warning mx-3" to="/todos-caixas">
            Voltar
          </Link>
          <div className="btn btn-success" onClick={fecharCaixa}>
            Fechar caixa
          </div>
        </div>
      </div>
      <div className=" d-flex flex-column align-items-end mt-2">
        <div className="alert alert-info">
          Valor do Caixa:{" "}
          <b> R$ {formatarValor(valorTotalVendas) || <span> 0.00</span>}</b>
        </div>
      </div>
      <hr />
      {message ? <div className="alert alert-success">{message}</div> : null}
      {!loading ? (
        <div className="d-flex align-items-start">
          <div className="border p-2  rounded w-100 d-flex">
            {renderTable()}
          </div>
          <ViewLancamento
            showModalView={showVendaModal}
            cancelView={cancelView}
            currentLancamento={currentVenda}
            formatarValor={formatarValor}
            formatarDataEHora={formatarDataEHora}
            message={message}
            setMessage={setMessage}
            error={error}
            setError={setError}
            userId={userId}
            valorTotalVendas={valorTotalVendas}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <img style={{ width: "100px" }} src={loadingGif} alt="Loading gif" />
        </div>
      )}

      {/* Modal do caixa */}
      <FecharCaixa
        show={showCaixaModal}
        onClose={closeFecharCaixaModal}
        message={message}
        setMessage={setMessage}
        error={error}
        setError={setError}
        userId={userId}
        valorTotalVendas={valorTotalVendas}
        formatarValor={formatarValor}
        caixa={caixa}
      />
    </div>
  );
};

export default FechandoCaixa;
