import React, { useEffect } from "react";
import InputMask from "react-input-mask";

export const ModalEditParte = ({
  editModalOpen,
  confirmEdit,
  cancelEdit,
  editingParte,
  setEditingParte,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingParte((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (editingParte) {
      setEditingParte(editingParte);
    }
  }, [editingParte]);

  return (
    <div
      className={`modal modal-lg ${editModalOpen ? "d-flex" : "d-none"}`}
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
      tabIndex="-1"
      role="dialog"
    >
      <div style={{ minWidth: "950px" }} className="modal-dialog ">
        <div className="modal-content shadow">
          <div className="modal-header">
            <h5 className="modal-title">
              Editando Parte {editingParte && editingParte.fullName}
            </h5>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label htmlFor="" className="form-label">
                  {editingParte.type === "fisica" ? (
                    <span> Nome completo</span>
                  ) : (
                    <span>Razão Social</span>
                  )}
                </label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  className="form-control"
                  placeholder=""
                  value={editingParte.fullName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="value">
                  {editingParte.type === "fisica" ? (
                    <span> CPF:</span>
                  ) : (
                    <span>CNPJ:</span>
                  )}
                </label>

                {editingParte.type === "fisica" ? (
                  <InputMask
                    className="form-control mb-3"
                    value={editingParte.document}
                    name="document"
                    type="text"
                    mask="999.999.999-99"
                    onChange={handleChange}
                  />
                ) : (
                  <InputMask
                    className="form-control mb-3"
                    value={editingParte.document}
                    name="document"
                    type="text"
                    mask="99.999.999/9999-99"
                    onChange={handleChange}
                  />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="value">E-mail:</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder=""
                  value={editingParte.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="value">Contato:</label>
                <input
                  type="text"
                  name="contact"
                  id="contact"
                  className="form-control"
                  placeholder=""
                  value={editingParte.contact}
                  onChange={handleChange}
                />
              </div>
              <div className="d-flex justify-content-end">
                <small>
                  Cadastro realizado em{" "}
                  {editingParte &&
                    new Date(
                      editingParte.createdAt.slice(0, -1)
                    ).toLocaleDateString("pt-br", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  h
                </small>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={cancelEdit}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={confirmEdit}
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
