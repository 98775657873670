import React, { useEffect, useRef, useState } from "react";
import api from "../utils/api.utils";
import PartesSearch from "../components/PartesSearch";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const AddDevolucao = ({
  error,
  setError,
  setMessage,
  formatarData,
  userData
}) => {
  const [parte, setParte] = useState(null);

  // const [selectedDate, setSelectedDate] = useState();

  const [vendas, setVendas] = useState([]);
  const [vendaSelecionada, setVendaSelecionada] = useState(null);
  const [vendaData, setVendaData] = useState(null);
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);
  // const [produtoSelecionado, setProdutoSelecionado] = useState("");
  const [motivo, setMotivo] = useState("");
  const [destinoValor, setDestinoValor] = useState("");

  const [typeFilter, setTypeFilter] = useState("vendas");

  const [origemImei, setOrigemImei] = useState("");

  // BUSCAR DADOS IMEI

  const [imei, setImei] = useState("");
  const [imeiData, setImeiData] = useState([]);
  const [valorImei, setValorImei] = useState("");

  const imeiInputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [destinoEstoque, setDestinoEstoque] = useState("");
  const [vendaImei, setVendaImei] = useState("");

  const handleImeiOrigemChange = (e) => {
    setOrigemImei(e.target.value);
    setImeiData("");
  };

  console.log(origemImei);

  useEffect(() => {
    if (!parte) {
      setTypeFilter("vendas");
    }
  }, []);

  const userId = userData.id;

  const navigate = useNavigate();

  function getCurrentFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedDate = getCurrentFormattedDate();

    if (
      (typeFilter === "vendas" &&
        parte !== null &&
        vendaSelecionada &&
        produtosSelecionados.length > 0 &&
        motivo !== "" &&
        destinoValor !== "") ||
      (typeFilter === "imei" &&
        imeiData.produtoId &&
        motivo !== "" &&
        destinoValor !== "")
    ) {
      let caixaAtual;

      try {
        const caixaAberto = await api.checkCaixaAberto(selectedDate);
        if (caixaAberto.msg) {
          caixaAtual = await api.abrirCaixa({ userId, selectedDate });
        } else {
          caixaAtual = caixaAberto;
        }
        let parteId = (parte && parte._id) || null;
        await api.addDevolucao({
          parteId,
          produtosSelecionados,
          // produtoSelecionado,
          imeiData,
          caixaAtual,
          vendaSelecionada,
          motivo,
          destinoValor,
          userData,
          valorImei,
          destinoEstoque,
          vendaImei
        });

        setParte(null);
        setProdutosSelecionados([]);
        setImeiData([]);
        setMessage("Devolução cadastrada com sucesso!");
        navigate("/meu-caixa/");
        setTimeout(() => {
          setMessage("");
        }, 4000);
      } catch (error) {
        console.log(error);
        setError("error");
      }
    } else {
      setError("Erro");
    }
  };

  useEffect(() => {
    if (parte) {
      const getVendas = async () => {
        try {
          setLoading(true);
          const vendasCliente = await api.getVendasCliente(parte._id);
          setVendas(vendasCliente);
        } catch (error) {
          setVendas([]);
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      getVendas();
    }
  }, [parte]);

  const selectVenda = async (venda) => {
    if (venda !== null) {
      setVendaSelecionada(venda);
      setProdutosSelecionados([]);
      try {
        await getVendaData();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getVendaData = async () => {
    try {
      if (vendaSelecionada !== null) {
        const data = await api.getVendasByID(vendaSelecionada);
        setVendaData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleProdutoSelecionado = (produto) => {
    // Verificar se o produto já está na lista
    const produtoIndex = produtosSelecionados.indexOf(produto);

    if (produtoIndex === -1) {
      // Se não estiver na lista, adicionar
      setProdutosSelecionados([...produtosSelecionados, produto]);
    } else {
      // Se já estiver na lista, remover
      const novaLista = [...produtosSelecionados];
      novaLista.splice(produtoIndex, 1);
      setProdutosSelecionados(novaLista);
    }
  };
  const cleanParte = () => {
    setParte(null);
    setVendaSelecionada(null);
    setProdutosSelecionados([]);
    setVendaData(null);
  };

  useEffect(() => {
    setError("");
    const updateVendaData = async () => {
      try {
        if (vendaSelecionada != null) {
          const data = await api.getVendasByID(vendaSelecionada);
          setVendaData(data);
        }
      } catch (error) {
        console.error("Erro ao obter dados da venda:", error);
      }
    };

    updateVendaData();
  }, [vendaSelecionada]);

  const formatarValor = (valor) => {
    const valorFormatado = valor.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return valorFormatado;
  };

  const handleTypeFiltro = (e) => {
    setTypeFilter(e.target.value);
    if (e.target.value === "vendas") {
      setProdutosSelecionados([]);
    }
    if (e.target.value === "imei") {
      setProdutosSelecionados([]);
      setVendaSelecionada(null);
    }
  };

  const handleOptionChange = (event) => {
    setDestinoEstoque(event.target.value);
  };

  const getDataImei = async () => {
    console.log(origemImei);
    try {
      setLoading(true);
      const data = await api.buscarImeiEditVenda(imei, { origemImei });
      setImeiData(data);
      if (data.transactions && data.transactions.length > 0) {
        const lastTransaction = data.transactions[data.transactions.length - 1];
        const matchedProducts = lastTransaction.produtos.filter(
          (produto) => produto.imeiId === data._id
        );
        setValorImei(matchedProducts[0].valorVenda);
        setVendaImei(
          data.transactions &&
            data.transactions[data.transactions.length - 1].number
        );
        setParte(
          data.transactions &&
            data.transactions[data.transactions.length - 1].parteId
        );
      } else {
        setValorImei([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const handleImeiChange = (event) => {
    setImei(event.target.value);
  };

  const handleImeiKeyPress = (event) => {
    if (event.key === "Enter") {
      getDataImei(event.target.value);
      setImei("");
    }
  };

  return (
    <div
      className="d-flex flex-column"
      style={{ marginTop: "70px", marginBottom: "100px", width: "75%" }}
    >
      <h5 className="mt-3">
        <i className="bi bi-cash-coin"></i> Realizando devolução
      </h5>

      <div className="d-flex">
        <div className="d-flex flex-column w-100 p-3">
          <div className="d-flex flex-column ">
            <div
              style={{ marginTop: "15px", backgroundColor: "white" }}
              className="d-flex flex-column border p-3 rounded col-12"
            >
              <div className="d-flex">
                <span>Filtrar por:</span>{" "}
                <div className="mx-3">
                  <input
                    type="radio"
                    id="vendas"
                    name="tipo_filtro"
                    value="vendas"
                    checked={typeFilter === "vendas"}
                    onChange={(e) => handleTypeFiltro(e)}
                  />
                    <label htmlFor="vendas">Vendas</label> {" "}
                </div>
                <div>
                  <input
                    type="radio"
                    id="imei"
                    name="tipo_filtro"
                    value="imei"
                    checked={typeFilter === "imei"}
                    onChange={(e) => handleTypeFiltro(e)}
                  />
                    <label htmlFor="imei">Imei</label>
                </div>
              </div>
              {/*TIPO IMEI*/}
              {typeFilter === "imei" && (
                <div>
                  <div className="mt-3">
                    <p>
                      O IMEI da devolução corresponde a um item vendido ou ainda
                      está no estoque?
                    </p>
                  </div>
                  <div
                    id="action-options"
                    className="btn-group btn-group-toggle d-flex mb-2"
                    data-toggle="buttons"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="origem"
                      id="origem1"
                      value="Vendido"
                      onChange={handleImeiOrigemChange}
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-outline-primary flex-fill"
                      htmlFor="origem1"
                    >
                      Vendido
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="origem"
                      id="origem2"
                      value="Estoque"
                      onChange={handleImeiOrigemChange}
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-outline-primary flex-fill"
                      htmlFor="origem2"
                    >
                      Estoque
                    </label>
                  </div>
                </div>
              )}
            </div>
            {typeFilter === "imei" && typeFilter && !imeiData.produtoId > 0 && (
              <div
                className="d-flex border p-3 rounded col-12"
                style={{
                  marginTop: "15px",
                  backgroundColor: "white",
                  overflowY: "auto",
                  maxHeight: "200px"
                }}
              >
                <div className="form-group col-12 col-md-7">
                  <label>Leitura de IMEI/Serial:</label>
                  <input
                    className="form-control"
                    type="text"
                    value={imei}
                    onChange={handleImeiChange}
                    placeholder="Insira o IMEI aqui!"
                    ref={imeiInputRef}
                    onKeyPress={handleImeiKeyPress}
                  />
                </div>
              </div>
            )}
            {typeFilter === "imei" && (
              <>
                {loading && <CircularProgress />}
                {imeiData.produtoId && (
                  <div
                    style={{ marginTop: "15px", backgroundColor: "white" }}
                    className="border p-3 rounded col-12"
                  >
                    <p style={{ borderBottom: "1px solid lightgrey" }}>
                      <b>Dados do IMEI</b>
                    </p>
                    <div className="d-flex flex-column">
                      <span>
                        Produto:{" "}
                        <b>
                          {imeiData.produtoId && imeiData.produtoId.descricao}
                        </b>{" "}
                        - (IMEI: {imeiData && imeiData.number}) | R${" "}
                        {formatarValor(valorImei)}
                      </span>
                      <span>
                        Venda:{" "}
                        <b>
                          {imeiData.transactions &&
                            imeiData.transactions[
                              imeiData.transactions.length - 1
                            ].type}
                          {imeiData.transactions &&
                            imeiData.transactions[
                              imeiData.transactions.length - 1
                            ].number}
                        </b>{" "}
                        (
                        {formatarData(
                          imeiData.transactions[
                            imeiData.transactions.length - 1
                          ].createdAt
                        )}
                        )
                      </span>
                      <span>
                        Cliente:{" "}
                        <b>
                          {imeiData.transactions &&
                            imeiData.transactions[
                              imeiData.transactions.length - 1
                            ].parteId.fullName}
                        </b>
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
            {/*TIPO VENDAS*/}
            {typeFilter === "vendas" && typeFilter && (
              <div>
                {!parte && (
                  <div className="d-flex flex-wrap justify-content-between">
                    <div
                      style={{ backgroundColor: "white" }}
                      className="border p-3 rounded form-group col-12"
                    >
                      <PartesSearch
                        title="Cliente"
                        parte={parte}
                        setParte={setParte}
                        setError={setError}
                        error={error}
                      />
                    </div>
                  </div>
                )}
                {/*PARTE*/}
                {parte && (
                  <div
                    style={{
                      backgroundColor: "white"
                    }}
                    className="border p-3 rounded"
                  >
                    <div>
                      Cliente:
                      <span className="mx-3">
                        <strong>
                          {parte.fullName} ({parte.document})
                        </strong>
                      </span>
                      <div
                        className="btn btn-danger"
                        style={{ width: "auto" }}
                        onClick={cleanParte}
                      >
                        <i className="bi bi-x-circle"></i>
                      </div>
                    </div>
                    <div style={{ overflowY: "auto", maxHeight: "200px" }}>
                      <table className="table">
                        <tbody>
                          {vendas &&
                            vendas.map((venda, index) => (
                              <tr
                                key={index}
                                onClick={() => selectVenda(venda._id)}
                                className={
                                  venda._id === vendaSelecionada
                                    ? "table-success"
                                    : "clickable"
                                }
                              >
                                <td>
                                  <i className="bi bi-hash p-2  mx-1"></i>
                                  <strong>
                                    {venda.type}
                                    {venda.number}
                                  </strong>
                                </td>
                                <td>
                                  <i className="bi bi-calendar3 p-2  mx-1"></i>
                                  {formatarData(venda.createdAt)}
                                </td>
                                <td>
                                  <i className="bi bi-person-circle p-2  mx-1"></i>
                                  {venda.parteId.fullName}
                                </td>
                                <td>
                                  <i className="bi bi-coin p-2  mx-1"></i>
                                  R$ {formatarValor(venda.valorTotal)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {/*VENDAS SELECIONADAS*/}
                {vendaSelecionada && (
                  <div
                    style={{ backgroundColor: "white" }}
                    className="border p-3 rounded  col-12"
                  >
                    <h6>Selecione os produtos que deseja devolver</h6>
                    <div className="d-flex flex-column">
                      {vendaData &&
                        vendaData.produtos.map((produto, index) => (
                          <div key={index} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`produto-${index}`}
                              checked={produtosSelecionados.includes(produto)}
                              onChange={() => handleProdutoSelecionado(produto)}
                            />
                            <label
                              style={{ fontWeight: "400" }}
                              className="form-check-label"
                              htmlFor={`produto-${index}`}
                            >
                              {produto.produtoId.descricao}
                              {produto.imeiId ? (
                                <> ({produto.imeiId.number})</>
                              ) : (
                                ""
                              )}
                              {" | R$"} {formatarValor(produto.valorVenda)}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*MOTIVO*/}
            {vendaSelecionada ||
            produtosSelecionados.length > 0 ||
            imeiData.produtoId ? (
              <>
                <div
                  className="p-3 border rounded col-12"
                  style={{ backgroundColor: "white" }}
                >
                  <label htmlFor="action-options" className="form-label">
                    Qual o destino do(s) produto(s):
                  </label>
                  <div
                    id="action-options"
                    className="btn-group btn-group-toggle d-flex mb-2"
                    data-toggle="buttons"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option1"
                      value="Fornecedor"
                      onChange={handleOptionChange}
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-outline-primary flex-fill"
                      htmlFor="option1"
                    >
                      Fornecedor
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option2"
                      value="Estoque"
                      onChange={handleOptionChange}
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-outline-primary flex-fill"
                      htmlFor="option2"
                    >
                      Estoque
                    </label>
                  </div>
                  <div className="d-flex flex-column m-0">
                    <small className="text-muted">
                      Ao selecionar <strong>Fornecedor</strong>, o produto não
                      ficará disponível para outra venda.{" "}
                    </small>

                    <small className="text-muted">
                      Ao selecionar <strong>Estoque</strong>, o produto voltará
                      a ficar disponível no sistema.
                    </small>
                  </div>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className="border p-3 rounded col-12"
                >
                  <label>Motivo da Devolução:</label>
                  <textarea
                    rows={3}
                    className="form-control"
                    value={motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                  />
                </div>
              </>
            ) : null}
            {vendaSelecionada ||
            produtosSelecionados.length > 0 ||
            imeiData.produtoId ? (
              <div
                style={{ backgroundColor: "white" }}
                className="border p-3 rounded form-group col-12"
              >
                <label>Destino do valor:</label>
                <select
                  className="form-select"
                  id="destinoValor"
                  name="destinoValor"
                  value={destinoValor}
                  onChange={(e) => setDestinoValor(e.target.value)}
                >
                  <option value="" disabled>
                    Selecione
                  </option>
                  <option value="carteira">Carteira</option>
                  <option value="caixa">Caixa</option>
                </select>
              </div>
            ) : null}
          </div>
          {error && <div className="alert alert-danger">{error}</div>}

          {produtosSelecionados.length > 0 ||
          (imeiData.produtoId && motivo !== "") ? (
            <div className="d-flex flex-column align-items-start">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Cadastrar
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AddDevolucao;
