import React, { useState } from "react";
import InputMask from "react-input-mask";
import api from "../utils/api.utils";

export const PartesAdd = ({
  show,
  onClose,
  message,
  setMessage,
  error,
  setError,
  userId,
  updateParteList
}) => {
  const [parteData, setPartesData] = useState({
    name: "",
    email: "",
    phone: "",
    document: "",
    type: "fisica"
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPartesData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (parteData) {
      try {
        const newParte = await api.addParte({ parteData, userId });

        // Em seguida, limpe o formulário e feche o modal.
        setPartesData({
          name: "",
          email: "",
          phone: "",
          document: "",
          type: "fisica"
        });
        onClose();
        updateParteList(newParte);
        setMessage("Parte cadastrada com sucesso!");
        setTimeout(() => {
          setMessage("");
        }, 5000);
      } catch (error) {
        setError(error);
      }
    }
  };

  return (
    <div className={`modal2 modal-lg ${show ? "show" : ""}`}>
      <div
        className="modal-dialog text-dark"
        style={{ top: "50%", transform: "translateY(-50%)" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Cadastro de Clientes & Fornecedores</h5>
            <div>
              <div
                className="d-flex align-items-center btn btn-outline-info  close"
                onClick={onClose}
              >
                <span>&times;</span>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <form>
              {error ? <div className="alert alert-danger">{error}</div> : null}
              <div className="form-group">
                <label>Tipo de Pessoa:</label>
                <select
                  name="type"
                  value={parteData.type}
                  onChange={handleChange}
                  className="form-control"
                >
                  <option value="fisica">Pessoa Física</option>
                  <option value="juridica">Pessoa Jurídica</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="name">
                  {parteData.type === "fisica" ? (
                    <span>Nome Completo</span>
                  ) : (
                    <span>Razão Social/Nome fantasia</span>
                  )}
                  :
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={parteData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="document">
                  {parteData.type === "fisica" ? (
                    <span>CPF</span>
                  ) : (
                    <span>CNPJ</span>
                  )}
                  :
                </label>
                {parteData.type === "fisica" ? (
                  <InputMask
                    className="form-control mb-3"
                    value={parteData.document}
                    name="document"
                    type="text"
                    mask="999.999.999-99"
                    onChange={handleChange}
                  />
                ) : (
                  <InputMask
                    className="form-control mb-3"
                    value={parteData.document}
                    name="document"
                    type="text"
                    mask="99.999.999/9999-99"
                    onChange={handleChange}
                  />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={parteData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Telefone:</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={parteData.phone}
                  onChange={handleChange}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Cadastrar
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
