import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import api from "./utils/api.utils";

import loadingGif from "./imgs/loading-state.gif";

import AppNavbar from "./components/AppNavbar";
import SideBar from "./components/Sidebar.js";
import { Footer } from "./components/Footer.js";

import User from "./views/User";

import { LoginPage } from "./views/LoginPage";
import Logout from "./views/Logout.js";

import { Auditoria } from "./views/Auditoria";

import MeuCaixa from "./views/MeuCaixa";
import { Partes } from "./views/Partes.js";

import ViewUser from "./views/ViewUser";
import TodosCaixas from "./views/TodosCaixas";
import FechandoCaixa from "./views/FechandoCaixa";
import Dispositivos from "./views/Dispositivos.js";
import AddProduto from "./views/AddProduto.js";
import Produtos from "./views/Produtos.js";
import Relatorio from "./views/Relatorio.js";
import AddTransaction from "./views/AddTransaction.js";
import AddTransactionVenda from "./views/AddTransactionsVenda.js";
import Transactions from "./views/Transactions.js";
import AddDevolucao from "./views/AddDevolucao.js";
import EditTransaction from "./views/EditTransaction.js";
import RedefinirSenha from "./views/RedefinirSenha.js";

function App() {
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const userToken = localStorage.getItem("token");
  const [loggedIn, setLoggedIn] = useState(!!userToken);

  const navigate = useNavigate();

  const handleLogin = (username) => {
    setLoggedIn(true);
    navigate("/meu-caixa/");
  };
  const handleSignup = (username, password, cpf, email) => {
    setLoggedIn(false);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    setLoggedIn(false);
    navigate("/login");
  };

  const userId = localStorage.getItem("userId");

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  //formatar data
  const formatarData = (dataParaFormatar) => {
    return new Date(dataParaFormatar).toLocaleDateString("pt-br", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });
  };
  //formatar data e hora
  const formatarDataEHora = (dataParaFormatar) => {
    return new Date(dataParaFormatar).toLocaleDateString("pt-br", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "America/Sao_Paulo",
      hour12: false
    });
  };
  const formatarValor = (valor) => {
    if (valor) {
      const valorFormatado = valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return valorFormatado;
    }
  };
  const [userData, setUserData] = useState("");

  useEffect(() => {
    if (loggedIn) {
      const getUser = async (userId) => {
        try {
          const data = await api.getUserNav(userId);
          setUserData(data);
        } catch (error) {
          console.log(error);
          setError(error);
        }
      };
      getUser(userId);
    }
  }, []);

  const [isAdmin, setIsAdmin] = useState(
    () => userData && userData.admin === true
  );

  useEffect(() => {
    if (userData && userData.admin !== undefined) {
      setIsAdmin(userData.admin === true);
    }
  }, [userData]);

  useEffect(() => {
    setTimeout(() => {
      setError(null);
      setMessage(null);
    }, 10000);
  }, [setMessage, setError]);

  return (
    <>
      {loggedIn ? (
        <>
          <AppNavbar
            onLogout={logout}
            isAdmin={isAdmin}
            userData={userData}
            setUserData={setUserData}
            setError={setError}
            userId={userId}
          />
          <SideBar
            onLogout={logout}
            isAdmin={isAdmin}
            userData={userData}
            setUserData={setUserData}
            setError={setError}
            userId={userId}
          />
        </>
      ) : null}

      <Routes>
        {loggedIn ? (
          <>
            <Route
              path="/logout/"
              element={
                <Logout
                  message={message}
                  setMessage={setMessage}
                  error={error}
                  setError={setError}
                />
              }
            />

            {/* NOVOS */}
            <Route
              path="/clientes-fornecedores/"
              element={
                <Partes
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  openModal={openModal}
                  closeModal={closeModal}
                  userData={userData}
                />
              }
            />
            <Route
              path="/produtos/"
              element={
                <Produtos
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userData={userData}
                />
              }
            />

            <Route
              path="/produtos/cadastrando"
              element={
                <AddProduto
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  openModal={openModal}
                  closeModal={closeModal}
                  userData={userData}
                />
              }
            />

            <Route
              path="/cadastrando/transaction/compras/"
              element={
                <AddTransaction
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                  userData={userData}
                />
              }
            />
            <Route
              path="/cadastrando/transaction/vendas/"
              element={
                <AddTransactionVenda
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                  userData={userData}
                />
              }
            />
            <Route
              path="/editando/transaction/:type/:id"
              element={
                <EditTransaction
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                  userData={userData}
                />
              }
            />
            <Route
              path="/estoque/"
              element={
                <Dispositivos
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  openModal={openModal}
                  closeModal={closeModal}
                  userData={userData}
                />
              }
            />
            <Route
              path="/:type/transactions/"
              element={
                <Transactions
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  openModal={openModal}
                  closeModal={closeModal}
                  userData={userData}
                  formatarValor={formatarValor}
                  formatarDataEHora={formatarDataEHora}
                />
              }
            />
            <Route
              path="/cadastrando/transaction/devolucao/"
              element={
                <AddDevolucao
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  openModal={openModal}
                  closeModal={closeModal}
                  userData={userData}
                  formatarValor={formatarValor}
                  formatarData={formatarData}
                />
              }
            />

            <Route
              path="/meu-caixa/"
              element={
                <MeuCaixa
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                  userData={userData}
                  formatarValor={formatarValor}
                />
              }
            />
            <Route
              path="/auditoria/"
              element={
                <Auditoria
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                />
              }
            />
            <Route
              path="/usuarios/"
              element={
                <User
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                />
              }
            />
            <Route
              path="/relatorios"
              element={
                <Relatorio
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  formatarData={formatarData}
                  formatarValor={formatarValor}
                  formatarDataEHora={formatarDataEHora}
                  openModal={openModal}
                  closeModal={closeModal}
                  showModal={showModal}
                />
              }
            />
            <Route
              path="/usuarios/:id"
              element={
                <ViewUser
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                />
              }
            />
            {/* <Route
              path="/caixas/"
              element={
                <Caixas
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                />
              }
            /> */}
            <Route
              path="/todos-caixas/"
              element={
                <TodosCaixas
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  userData={userData}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                />
              }
            />
            {/* <Route
              path="/caixas/cadastrando/"
              element={
                <AddCaixa
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                />
              }
            /> */}
            {/* <Route
              path="/caixas/:id/"
              element={
                <ViewCaixa
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                />
              }
            /> */}

            <Route
              path="/caixa/fechando/:caixa_id"
              element={
                <FechandoCaixa
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  loadingGif={loadingGif}
                  error={error}
                  setError={setError}
                  userId={userId}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  openModal={openModal}
                  closeModal={closeModal}
                  formatarData={formatarData}
                  formatarDataEHora={formatarDataEHora}
                />
              }
            />
          </>
        ) : (
          <>
            <Route
              path="/"
              element={
                <LoginPage
                  handleLogin={handleLogin}
                  handleSignup={handleSignup}
                  message={message}
                  setMessage={setMessage}
                  error={error}
                  setError={setError}
                />
              }
            />
            <Route
              path="/login"
              element={
                <LoginPage
                  handleLogin={handleLogin}
                  handleSignup={handleSignup}
                  message={message}
                  setMessage={setMessage}
                  error={error}
                  setError={setError}
                />
              }
            />
            <Route
              path="/redefinir-senha/:id"
              element={<RedefinirSenha setMessage={setMessage} />}
            />
            <Route
              path="/logout/"
              element={
                <Logout
                  message={message}
                  setMessage={setMessage}
                  error={error}
                  setError={setError}
                />
              }
            />
          </>
        )}
      </Routes>
      {loggedIn ? <Footer /> : null}
    </>
  );
}

export default App;
